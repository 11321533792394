import React from 'react';

const CouponLogs = () => {
  return (
    <div>
      Hello , I am CouponLogs!
    </div>
  );
};

export default CouponLogs;