import React from 'react';

const FaqIndex = () => {
  return (
    <div>
      Hello , I am FaqIndex!
    </div>
  );
};

export default FaqIndex;