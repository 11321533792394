import React from 'react';

const TaxonomyIndex = () => {
  return (
    <div>
      Hello , I am TaxonomyIndex!
    </div>
  );
};

export default TaxonomyIndex;