import React from 'react';

const RecentOrder = () => {
    return (
        <div>
            I am recent Order
        </div>
    );
};

export default RecentOrder;