import React from 'react';

const PageIndex = () => {
  return (
    <div>
      Hello , I am PageIndex!
    </div>
  );
};

export default PageIndex;