import React from 'react';

const ManageProducts = () => {
  return (
    <div>
      Hello , I am ManageProducts!
    </div>
  );
};

export default ManageProducts;