import React from 'react';

const CustomerIndex = () => {
  return (
    <div>
      Hello , I am CustomerIndex!
    </div>
  );
};

export default CustomerIndex;